import { constToOptions } from '../util.js'

export const genderList = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
]

export const btnTableList = [
  {
    label: 'Permission Code',
    props: 'name'
    // width: '200'
  },
  {
    label: 'Button',
    props: 'name'
  }
  // {
  //   label: 'URL',
  //   props: 'uri',
  //   width: '80'
  // },
  // {
  //   label: 'Methods',
  //   props: 'methods',
  //   width: '80'
  // }
]

export const cardStatus = {
  lock: 'LOCKED',
  loss: 'LOSSED',
  unlock: 'NORMAL'
}
export const cardSatusValue = {
  lock: '0',
  loss: '1',
  unlock: '2'
}
export const cardSatusOption = constToOptions(cardStatus, cardSatusValue)

export const appStatusLabel = {
  normal: 'Normal',
  draft: 'Draft',
  disabled: 'Disabled'
}

export const appStatusValue = {
  normal: '00',
  disabled: '02'
}

export const appStatusIcon = {
  normal: 'success',
  draft: 'info',
  disabled: 'danger'
}

export const appStatusOption = constToOptions(appStatusLabel, appStatusValue)

export const cardTypeLabel = {
  debit: 'Debit',
  credit: 'Credit'
}
export const cardTypeValue = {
  debit: '0',
  credit: '1'
}
export const cardTypeOption = constToOptions(cardTypeLabel, cardTypeValue)

export const logStatus = {
  success: 'Success',
  fail: 'Failed'
}

export const logStatusValue = {
  success: 0,
  fail: 1
}

export const messageStatus = {
  unRead: 'UnRead',
  read: 'Read'
}

export const messageStatusValue = {
  unRead: '00',
  read: '01'
}
export const messageStatusOption = constToOptions(
  messageStatus,
  messageStatusValue
)

export const yesNoOption = [
  {
    label: 'Yes',
    value: '00'
  },
  {
    label: 'No',
    value: '01'
  }
]

export const jobStatusLabel = {
  enable: 'Enable',
  disable: 'Disable'
}

export const jobStatusValue = {
  enable: '00',
  disable: '01'
}
export const jobStatusOption = constToOptions(jobStatusLabel, jobStatusValue)

export const trueFalseLabel = {
  true: 'true',
  false: 'false'
}

export const trueFalseOption = constToOptions(trueFalseLabel, trueFalseLabel)

export const jobFrequencyLabel = {
  everyday: 'Every Day',
  everyMonth: 'Every Month',
  everyPeriod: 'Every Period',
  everyQuarter: 'Every Quarter',
  everyWeek: 'Every Week',
  everyYear: 'Every Year',
  oneTime: 'One Time'
}

export const jobFrequencyValue = {
  everyday: 'EVD',
  everyMonth: 'EVM',
  everyPeriod: 'EVP',
  everyQuarter: 'EVQ',
  everyWeek: 'EVW',
  everyYear: 'EVY',
  oneTime: 'ONCE'
}
export const jobFrequencyOption = constToOptions(
  jobFrequencyLabel,
  jobFrequencyValue
)

export const jobExecuteStatusLabel = {
  success: 'Success',
  unexecuted: 'Unexecuted',
  failed: 'Failed'
}

export const jobExecuteStatusValue = {
  success: '00',
  unexecuted: '01',
  failed: '02'
}

export const jobExecuteStatusOption = constToOptions(
  jobExecuteStatusLabel,
  jobExecuteStatusValue
)

export const moduleList = {
  'module-login': 'Login',
  'app-home': 'Home',
  'app-switch': 'Switch Status',
  'app-batch-job': 'Batch Job',
  'app-atm-status': 'ATM Status',
  'app-configure': 'Configuration',
  'app-common-conf': 'Common Configuration',
  'app-monitor-online': 'Terminal Online',
  'app-card-conf': 'Card Configuration',
  'app-key-conf': 'Key Configuration',
  'app-authorization-conf': 'Authorization Configuration',
  'app-membership-conf': 'Member Configuration',
  'app-atm-conf': 'ATM Configuration',
  'app-clearance-conf': 'Clearance Configuration',
  'app-user-conf': 'User Configuration',
  'app-profile-conf': 'Profile Configuration',
  'app-card-manage': 'Card Management',
  'app-card-register': 'Card Registration',
  'app-card-overview': 'Card Overview',
  'app-psamcard-overview': 'PSAM Card Overview',
  'app-card-vendor': 'Card Vendor',
  'app-card-model': 'Card Model',
  'app-authorization': 'Authorization',
  'app-membership-auth': 'Member',
  'app-issuer': 'Issuer',
  'app-acquirer': 'Acquirer',
  'app-membership-manage': 'Member Management',
  'app-application': 'Member Registration',
  'app-issuercertification': 'Issuer Certification',
  'app-acquirercertification': 'Acquirer Certification',
  'app-key-manage': 'Key Management',
  'app-keys-local': 'Local Master Key',
  'app-keys-national': 'National Master Key',
  'app-keys-city': 'City Master Key',
  'app-keys-terminal': 'ATM Master Key',
  'app-keys-mac': 'ATM MAC Key',
  'app-keys': 'Issuance Key',
  'app-atm-manage': 'ATM Management',
  'app-atm-register': 'ATM Registration',
  'app-atm-overview': 'ATM Overview',
  'app-atm-params': 'ATM Parameter',
  'app-atm-vendor': 'ATM Vendor',
  'app-atm-model': 'ATM Model',
  'app-clearance': 'Clearance',
  'app-end-day': 'End Of Day',
  'app-import': 'Import',
  'app-export': 'Export',
  'app-user-manage': 'User Management',
  'app-user-audit': 'Auth',
  'app-users': 'User',
  'app-user-role': 'Role',
  'app-user-group': 'Resource Group',
  'app-my-profile': 'My Profile',
  'app-messages': 'Message Center',
  'app-profiles': 'Profiles',
  'app-my-settings': 'Settings',
  'app-my-userlog': 'Operator Log',
  'app-report-center': 'Report Center',
  'app-transaction-report': 'Transaction Report',
  'app-configure-report': 'Configuration Report',
  'app-card-report': 'Card Report',
  'app-membership-report': 'Member Report',
  'app-key-report': 'Key Report',
  'app-atm-report': 'ATM Report',
  'app-help-center': 'Help Center',
  'app-help': 'Online Help',
  'app-about': 'About',
  'app-demo': 'Demo'
}
