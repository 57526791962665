import { basicRequiredRule } from '@/template/rule'
import { getCommonParamsOptions } from '@/utils/common'
// import Validator from '@/validators'
import { trueFalseOption } from '@/utils/constant/const.js'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'fileUrl',
      type: 'file',
      label: 'file.file',
      otherOptions: {
        acceptType: '.apk,.gz,.tar.gz',
        limitSize: 500
      }
    },
    {
      field: 'versionCode',
      type: 'input',
      label: 'file.version-code',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'versionName',
      type: 'input',
      label: 'file.version-name',
      otherOptions: {
        maxlength: 100,
        disabled: true
      }
    },
    {
      field: 'packageName',
      type: 'input',
      label: 'file.package-name',
      otherOptions: {
        maxlength: 200,
        disabled: true
      }
    },

    {
      field: 'appEnvType',
      type: 'select',
      label: 'file.application-environment',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_env_type')
    },
    // {
    //   field: 'Signature',
    //   type: 'input',
    //   label: 'file.signature'
    // },
    {
      field: 'signed',
      type: 'select',
      label: 'file.is-sign',
      options: trueFalseOption
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description'
    }
  ],
  validateRules: {
    // versionCode: [
    //   basicRequiredRule,
    //   { validator: Validator.integer, message: 'Must be integer' }
    // ],
    appEnvType: [basicRequiredRule],
    fileUrl: [basicRequiredRule]
  }
}
