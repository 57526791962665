export const basicRequiredRule = {
  required: true,
  message: 'general.required',
  trigger: 'change'
}

export const ipRule = {
  pattern:
    /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/,
  message: 'validator.ip-error',
  trigger: 'change'
}
